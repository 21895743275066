import React from 'react'
import { Flex, Text, Card, Button } from '@Common/Components'

const CardLine = ({ title, value, bold }) => {
  return (
    <Flex row as>
      <Flex as width={60}>
        <Text bold={bold} value={title} size={12} />
      </Flex>
      <Flex as width={140}>
        <Text bold={bold} value={value} size={12} />
      </Flex>
    </Flex>
  )
}

export const CardResult = ({ nome, cogn, cell, ind, cit, mail, cfis, keys = {}, privacy, idAnagrafica, rags, onContactSelection, type, buttonLabel, buttonFunction }) => {
  const isCliente = !!keys.CLI
  const email = mail || (keys.WEB && keys.WEB.includes('@') ? keys.WEB : '')
  const correctStyle = type === 'A' ? styles.cardButtonApptour() : styles.cardButton(isCliente)
  const correctLabel = type === 'A' ? 'CLIENTE APPTOUR' : isCliente ? 'CLIENTE:' : 'PROSPECT'
  return (
    <Card style={correctStyle} onClick={() => onContactSelection && onContactSelection({ nome, cogn, cell, ind, cit, keys, idAnagrafica, mail: email, cfis, privacy, rags })}>
      <Flex as js>
        <CardLine bold title={correctLabel} value={keys.CLI} />
        <CardLine title='Nome:' value={(nome || '') + ' ' + (cogn || '')} />
        <CardLine title='Telefono:' value={cell} />
        <CardLine title='Email:' value={email} />
        <CardLine title='Indirizzo:' value={ind} />
        <CardLine title='Città:' value={cit} />
        <CardLine title='Codice Fis.:' value={cfis} />
      </Flex>
      {buttonLabel && <Flex><Button label='Unisci' rich style={{ backgroundColor: 'rgb(50, 153, 51)', color: 'white' }} onClick={buttonFunction || null} /></Flex>}
    </Card>
  )
}

const styles = {
  cardButton: (isCliente) => ({
    backgroundColor: isCliente ? 'rgba(255, 255, 255, 1)' : 'rgba(245, 239, 137, 1)',
    padding: 10,
    marginTop: 10,
    width: '260px',
    cursor: 'pointer',
    marginLeft: 10
  }),
  cardButtonApptour: () => ({
    backgroundColor: 'rgba(179, 231, 250, 1)',
    padding: 10,
    marginTop: 10,
    width: '260px',
    cursor: 'pointer',
    marginLeft: 10
  })
}
